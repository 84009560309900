import React from "react"
import Layout from "../../../containers/layout"
import ChartList from "../../../cm-lib/reference/DetailChart.json"
import Ju from "../../../cm-lib/reference/Ju.json"
import {
  DEAULT_DATE_FORMAT,
  BRANCH,
  DATETIME_FORMAT,
  DIRECTION,
} from "../../../cm-lib/constants"
import { format, parseISO, addDays, differenceInCalendarDays } from "date-fns"
import Common from "./common"

const search = (chart, palace) => {
  const now = new Date()
  const searchEndDate = addDays(now, 5)
  const diff = differenceInCalendarDays(searchEndDate, now)
  return [...Array(diff).keys()]
    .map(d => {
      const compareDate = addDays(now, d)
      const key = format(compareDate, DEAULT_DATE_FORMAT)

      return (
        ChartList &&
        ChartList[key]
          .reduce((a, e) => {
            const formationIndex = chart.findIndex(
              o => e.structure === o.structure && e.hour === o.hour
            )
            if (
              formationIndex > -1 &&
              palace.includes(DIRECTION[chart[formationIndex].e.i])
            ) {
              return a.concat([
                {
                  ...e,
                  f: {
                    ...chart[formationIndex].e,
                    d: DIRECTION[formationIndex],
                  },
                },
              ])
            }
            return a
          }, [])
          .map(ee => {
            const hour = BRANCH.indexOf(ee.hour.substr(1, 1)) * 2
            return {
              ...ee,
              date: `${key}T${hour.toString().padStart(2, "0")}:00:00`,
            }
          })
      )
    })
    .reduce((a, n) => a.concat(n), [])
}

const handleSearch = (searchFormation, palace) => {
  const found = Ju.filter(v =>
    v.chart.find(g => g.f && g.f.indexOf(searchFormation) > -1)
  ).map(e => ({
    structure: e.structure,
    hour: e.hour,
    e: {
      ...e.chart.find(e => e.f === searchFormation),
      i: e.chart.findIndex(e => e.f === searchFormation),
    },
  }))
  const result = search(found, palace)
  const display = (v, square) =>
    `${format(parseISO(`${v}`), DATETIME_FORMAT)} ${square.heaven} ${
      square.star
    } ${square.door} ${square.god} ${square.earth} ${DIRECTION[square.i]}`
  return result.map((v, i) => ({ ...v, display: display(v.date, v.f) }))
}

const getChart = (dateObject, updateChart) => {
  const hour = dateObject.getHours()
  const chartDate = format(dateObject, "yyyyMMdd")
  const ch = ChartList[chartDate][Math.floor(hour / 2)]
  const foundChart = Ju.find(
    e => e.structure === ch.structure && e.hour === ch.hour
  )
  updateChart(foundChart)
}

const Home = () => {
  return (
    <Layout>
      <Common
        heading="Qi Men Dun Jia Year Chart"
        handleSearch={handleSearch}
        onDateChanged={getChart}
      />
    </Layout>
  )
}

export default Home
